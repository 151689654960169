(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/pay/assets/javascripts/account-services/account-balance.js') >= 0) return;  svs.modules.push('/components/tur/pay/assets/javascripts/account-services/account-balance.js');

'use strict';

const _excluded = ["cancel", "saveState", "product"],
  _excluded2 = ["cancel", "syndicate", "accountBalance"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function depositDialog(_ref) {
  let {
    title,
    actions,
    accountBalance,
    price,
    message = null
  } = _ref;
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    type: svs.ui.dialog.type.INFO,
    icon: 'deposit',
    title,
    message: message || [{
      type: svs.ui.dialog.message.TEXT,
      text: "Saldo: ".concat(svs.utils.format.Currency(accountBalance), " kr"),
      align: svs.ui.dialog.message.align.CENTER
    }, {
      type: svs.ui.dialog.message.TEXT,
      text: "Pris: ".concat(svs.utils.format.Currency(price), " kr"),
      align: svs.ui.dialog.message.align.CENTER
    }],
    actions
  }));
}
function showNotEnoughMoneyDialog(_ref2) {
  let {
      cancel,
      saveState,
      product
    } = _ref2,
    args = _objectWithoutProperties(_ref2, _excluded);
  depositDialog(_objectSpread(_objectSpread({}, args), {}, {
    title: 'Du behöver sätta in pengar för att kunna betala ditt spel.',
    actions: [{
      type: svs.ui.dialog.button.SECONDARY,
      title: 'Avbryt',
      callback: (e, form, dialog) => {
        dialog.close();
        cancel();
      }
    }, {
      type: svs.ui.dialog.button.SECONDARY,
      title: 'Sätt in',
      callback: (e, form, dialog) => {
        dialog.close(() => {
          saveState(() => svs.utils.form.postToUrl(svs.core.urlMapping.get('transactionsHome'), {
            returnUrl: window.location,
            product,
            price: args === null || args === void 0 ? void 0 : args.price
          }));
        });
      }
    }]
  }));
}
function showSyndicateNotEnoughMoneyDialog(_ref3) {
  let {
      cancel,
      syndicate,
      accountBalance
    } = _ref3,
    args = _objectWithoutProperties(_ref3, _excluded2);
  const {
    syndicateName = ''
  } = syndicate;
  depositDialog(_objectSpread(_objectSpread({}, args), {}, {
    title: "".concat(syndicateName, " har inte tillr\xE4ckligt saldo f\xF6r att betala spelet."),
    actions: [{
      type: svs.components.dialog.button.SECONDARY,
      title: 'Avbryt',
      callback: (e, form, dialog) => {
        dialog.close();
        cancel();
      }
    }, {
      type: svs.components.dialog.button.PRIMARY,
      title: 'Till mina lag',
      callback: (e, form, dialog) => {
        dialog.close(() => {
          svs.utils.url.navigate(svs.core.urlMapping.get('playTogetherTeams'));
        });
      }
    }],
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: "Spelbart saldo i laget: ".concat(svs.utils.format.Currency(accountBalance), " kr"),
      align: svs.ui.dialog.message.align.CENTER
    }, {
      type: svs.ui.dialog.message.TEXT,
      text: "I Mina lag kan du se lagmedlemmarnas saldo.",
      align: svs.ui.dialog.message.align.CENTER
    }]
  }));
}

function accountBalance(_ref4) {
  let {
    next,
    cancel,
    saveState,
    price,
    product,
    isSyndicate,
    syndicate
  } = _ref4;
  if (isSyndicate) {
    if (price > svs.utils.format.CurrencyFromJupiterString(syndicate.playableAmount)) {
      svs.tur.pay.logger.info("Not enough money. Price: ".concat(price, ", balance: ").concat(syndicate.playableAmount, ", syndicateId: ").concat(syndicate.syndicateId));
      return showSyndicateNotEnoughMoneyDialog({
        cancel,
        syndicate,
        accountBalance: syndicate.playableAmount,
        price
      });
    }
    return next({
      accountBalance: syndicate.playableAmount
    });
  }
  svs.components.account_balance.get(true, balance => {
    if (price > svs.utils.format.CurrencyFromJupiterString(balance)) {
      svs.tur.pay.logger.info("Not enough money. Price: ".concat(price, ", balance: ").concat(balance));
      return showNotEnoughMoneyDialog({
        cancel,
        saveState,
        accountBalance: balance,
        price,
        product
      });
    }
    next({
      accountBalance: balance
    });
  });
}
svs.tur.pay.account_services.accountBalance = accountBalance;

 })(window);